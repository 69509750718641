import React from "react";
import styles from "./Row.module.scss";
import Info from "../../../../../../img/IconComponents/Info.jsx";
import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Row = ({ orderDate, toPay, tokens, id, is_coinbase }) => {
  const {t} = useTranslation()
  return (
    <div className={styles.row}>
      <div>
        {localStorage.getItem("i18n") == "EN"
          ? moment(orderDate).format("DD.MM.YYYY hh:mm a")
          : moment(orderDate).format("HH:mm [Uhr], DD.MM.YYYY")}
      </div>
      <div>{tokens}{" "}{t("Tokens")}</div>
      <div>
        {toPay.toLocaleString("en-US").replace(",", ".")} EUR
        
          <Link to={`/order/${id}`}>
            <Info />
          </Link>
        
      </div>
    </div>
  );
};

export default Row;
