/** @format */

import axios from 'axios';

// export const API_URL = `http://cbl.ex2.team:8084`;
// export const API_URL = `http://localhost:3333/api`;
// export const API_URL = `http://localhost:3333/api`;
// export const API_URL = `http://plant.ex2.team:3002/api`;
// export const API_URL = `http://plant.dev.ex2.team:3003/api`;
// export const API_URL = `http://plant.ex2.team:3003/api`;
export const API_URL = `https://back.anbaugesellschaft.de/api`;

//Should be process.env.REACT_APP_API_URL!
// export const API_URL =
//   process.env.REACT_APP_API_URL;
// export const API_URL =
// 'https://backplant.ex2.team/api';

// export const FRONT_URL = 'http://localhost:3000';
export const FRONT_URL = "https://anbaugesellschaft.de";

//Should be process.env.REACT_APP_FRONT_URL!
// export const FRONT_URL =
//   'https://development.anbaugesellschaft.de';
// export const FRONT_URL =
// 'https://development.anbaugesellschaft.de';
// export const FRONT_URL =
//   process.env.REACT_APP_FRONT_URL;

const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    'token',
  )}`;
  return config;
});

$api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response?.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.get(
          `${API_URL}/v1/auth/refresh`,
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${localStorage.getItem(
                'tokenRefresh',
              )}`,
            },
          },
        );
        if(response.status==401){
          throw new Error('Unauthorized')
        }
        localStorage.setItem(
          'token',
          response.data.accessToken,
        );
        localStorage.setItem(
          'tokenRefresh',
          response.data.refreshToken,
        );
        return $api.request(originalRequest);
      } catch (e) {
        localStorage.removeItem('token');
        localStorage.removeItem('tokenRefresh');
        localStorage.removeItem('role');

        const locationsNotToNavigateLogin = [
          '/verify-user',
          '/ad/',
          '/agb',
          '/imprint',
          '/risk-information',
          '/check-email',
          '/widerrufsrecht',
          '/contact',
          '/coinbase',
          '/press',
          '/privacy',
          '/register',
          '/anbaugesellschaft-einladung',
          'reset-password',
          '/login',
          'check-contract',
          '/check-email-reset',
          '/password-changed',
          '/verify',
          '/news',
          '/token-dect',
          '/grow-dect'
        ];



        if (
          window.location.pathname.toLowerCase() != '/login' &&
          window.location.pathname.toLowerCase() != '/'&&
          (!locationsNotToNavigateLogin.find(
            (el) =>
              window.location.pathname.toLowerCase().includes(
                el,
              ),
          )  
        
        )
          
        ) {
          window.location = '/login';
        }
       
      }
    }
    throw error;
  },
);

export default $api;
